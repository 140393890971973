<template>
	<v-main class="py-0" >
		<v-container fluid >
			<v-row>
				<v-col
					cols="12"
					md="6"
				>
					<fperfil />
				</v-col>
			</v-row>
		</v-container>
	</v-main>
</template>

<script>
	import { fperfil } from '@/components/ui'
	export default {
		name: 'vwFranchiseHome',

		components: {
			fperfil
		},

		beforeMount(){
			this.$root.$emit('franchise-open-sidebar')
		},

		beforeDestroy(){
			this.$root.$emit('franchise-close-sidebar')
		}
	}
</script>